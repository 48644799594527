import React, { useState, useEffect, useContext } from 'react';
import { navigate, useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { FirebaseContext } from 'context';
import {
  LoadingSpinner,
  LoadingSpinnerFullScreenWrapper,
  ProtectedRoute,
  SEO,
  Button
} from 'components';
import BaseSelect from 'react-select';
import FixRequiredSelect from 'components/Forms/FixRequiredSelect';
import { defaultColors } from 'styles';

const reminderTypes = [
  {
    label: '48 Hours Before',
    value: 'reminder-email-48-hours-before'
  },
  {
    label: '24 Hours Before',
    value: 'reminder-email-24-hours-before'
  },
  {
    label: 'Same Day',
    value: 'reminder-email-same-day'
  }
];

function FormSelect(props) {
  return <FixRequiredSelect {...props} SelectComponent={BaseSelect} options={props.options} />;
}

const customDropdownMenuStyles = (colors) => ({
  container: (provided, state) => ({
    ...provided,
    alignItems: 'center',
    alignSelf: 'stretch',
    background: 'transparent',
    border: state.isFocused ? `0.143em solid ${colors.primary}` : `1px solid ${colors.primary}`,
    borderRadius: '2px',
    display: 'flex',
    fontFamily: "'Noto Sans', sans-serif",
    fontSize: '0.875rem',
    fontWeight: 'bold',
    height: '2.5rem',
    letterSpacing: '0.036em',
    padding: '0',
    marginBottom: '12px'
  }),
  control: (provided, state) => ({
    ...provided,
    background: 'transparent',
    border: 'none',
    boxShadow: 'none',
    padding: state.isFocused ? '0 0 0 0.571em' : '0 0 0 0.571em',
    transition: 'none',
    cursor: 'pointer',
    height: '100%',
    width: '100%'
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: colors.primary,
    '&:hover': {
      color: colors.primary
    }
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  input: (provided) => ({
    ...provided,
    color: colors.primary,
    margin: 0,
    width: '100%'
  }),
  menu: (provided) => ({
    ...provided,
    background: colors.primary,
    border: `0.25em solid ${colors.primary}`,
    borderRadius: '0',
    fontFamily: "'Noto Sans', sans-serif",
    fontSize: '0.875rem',
    fontWeight: 'bold',
    left: '-0.143em',
    letterSpacing: '0.036em',
    margin: '0',
    width: 'calc(100% + 0.286em)'
  }),
  menuList: (provided) => ({
    ...provided,
    padding: 0,
    '::-webkit-scrollbar': {
      width: '11px'
    },
    '::-webkit-scrollbar-track': {
      background: colors.primary
    },
    '::-webkit-scrollbar-thumb': {
      background: colors.secondary,
      height: '1.25rem'
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: colors.tertiary
    }
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    color: colors.primary
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? colors.tertiary : 'transparent',
    paddingLeft: '0.571em',
    color: '#fff',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: colors.secondary
    }
  }),
  placeholder: (provided) => ({
    ...provided,
    color: colors.primary,
    fontFamily: "'Noto Sans', sans-serif",
    fontSize: '0.875rem',
    fontStyle: 'italic',
    fontWeight: 'normal',
    margin: 0,
    opacity: 0.75
  }),
  singleValue: (provided) => ({
    ...provided,
    color: colors.primary,
    margin: 0
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: 0
  })
});

function Admin() {
  const { loading, firebase, user } = useContext(FirebaseContext);

  const [uidOfUserToEditPermissionsOf, setUidOfUserToEditPermissionsOf] = useState('');
  const [grantingUserAdminPermissions, setGrantingUserAdminPermissions] = useState(false);
  const [grantingUserModeratorPermissions, setGrantingUserModeratorPermissions] = useState(false);
  const [removingUserModeratorPermissions, setRemovingUserModeratorPermissions] = useState(false);
  const [grantingUserEditorPermissions, setGrantingUserEditorPermissions] = useState(false);
  const [removingUserEditorPermissions, setRemovingUserEditorPermissions] = useState(false);

  const [selectedEvent, setSelectedEvent] = useState('');

  const [generatingLoggedInOnTheDayReport, setGeneratingLoggedInOnTheDayReport] = useState(false);
  const [generatingEventCommentsReport, setGeneratingEventCommentsReport] = useState(false);

  const [sendingEventReminderEmail, setSendingEventReminderEmail] = useState(false);
  const [sendingEventCancellationEmail, setSendingEventCancellationEmail] = useState(false);
  const [eventReminderType, setEventReminderType] = useState('');

  const [gettingInvalidUIDs, setGettingInvalidUIDs] = useState(false);

  const [amountOfDummyUsers, setAmountOfDummyUsers] = useState('');
  const [addingNewDummyUsers, setAddingNewDummyUsers] = useState(false);
  const [deletingAllDummyUsers, setDeletingAllDummyUsers] = useState(false);

  const eventData = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/(src/data/events)/" } }) {
        edges {
          node {
            frontmatter {
              eid
              name
              title
              slug
              start
              end
              location
              colors {
                primary
                secondary
                tertiary
              }
            }
          }
        }
      }
    }
  `);

  const events = eventData.allMarkdownRemark.edges
    .map(({ node }) => ({
      ...node.frontmatter,
      value: node.frontmatter.eid,
      label: node.frontmatter.eid
    }))
    .sort((a, b) => a.eid - b.eid);

  useEffect(() => {
    if (user && !user.isAdmin) {
      navigate('/');
    }
  }, [loading, firebase, user]);

  const handleGenerateLoggedInOnTheDayReport = async () => {
    setGeneratingLoggedInOnTheDayReport(true);

    const uidArray = [];

    await firebase.fetchAllFirebaseAuthUserData().then(({ data: allUsers }) => {
      allUsers.forEach((_user) => {
        // Go here to convert the event date and time to a timestamp - https://timestamp.online/
        // and then add three zeros onto the end to make it compatible with the Firebase timestamp. //
        if (new Date(_user.metadata.lastRefreshTime).getTime() >= 1646897400000) {
          uidArray.push(_user.uid);
        }
      });
    });

    const userNamesAndEmailsArray = [];

    const fetchAllFirestoreUserData = async () => {
      const promises = [];

      uidArray.forEach((uid) => {
        promises.push(firebase.getUserFromDatabaseWithUID({ uid }));
      });

      const promiseResults = await Promise.all(promises);

      promiseResults.forEach((res) => {
        if (!res.empty) {
          const { title, name, email, profession, company, eventsUserCanAccess } =
            res.docs[0].data();
          if (eventsUserCanAccess.includes(selectedEvent.eid)) {
            userNamesAndEmailsArray.push({
              title,
              name,
              email,
              profession,
              company
            });
          }
        }
      });
    };

    await fetchAllFirestoreUserData();

    const sortedUsers = userNamesAndEmailsArray.sort((a, b) =>
      a.name.toLowerCase() !== b.name.toLowerCase()
        ? a.name.toLowerCase() < b.name.toLowerCase()
          ? -1
          : 1
        : 0
    );

    const sortedUsersWithRowNumbers = sortedUsers.map((entry, i) => ({
      rowNumber: i + 1,
      ...entry
    }));

    firebase
      .generateLoggedInOnTheDayReport({
        users: sortedUsersWithRowNumbers,
        eventName: selectedEvent.name
      })
      .then(({ data: _data }) => window.open(_data, '_blank') || window.location.replace(_data))
      .catch(console.error)
      .finally(() => setGeneratingLoggedInOnTheDayReport(false));
  };

  const handleMakeUserASiteAdmin = async () => {
    try {
      setGrantingUserAdminPermissions(true);
      await firebase.grantUserAdminPermissions({
        uid: uidOfUserToEditPermissionsOf
      });
    } catch (error) {
      console.error(error);
    } finally {
      setGrantingUserAdminPermissions(false);
    }
  };

  const handleMakeUserAnEventModerator = async () => {
    try {
      setGrantingUserModeratorPermissions(true);
      await firebase.grantUserModeratorPermissions({
        uid: uidOfUserToEditPermissionsOf,
        eid: selectedEvent.eid
      });
    } catch (error) {
      console.error(error);
    } finally {
      setGrantingUserModeratorPermissions(false);
    }
  };

  const handleRemoveUserModeratorPermissions = async () => {
    try {
      setRemovingUserModeratorPermissions(true);
      await firebase.removeUserModeratorPermissions({
        uid: uidOfUserToEditPermissionsOf
      });
    } catch (error) {
      console.error(error);
    } finally {
      setRemovingUserModeratorPermissions(false);
    }
  };

  const handleMakeUserAnEventEditor = async () => {
    try {
      setGrantingUserEditorPermissions(true);
      await firebase.grantUserEditorPermissions({
        uid: uidOfUserToEditPermissionsOf,
        eid: selectedEvent.eid
      });
    } catch (error) {
      console.error(error);
    } finally {
      setGrantingUserEditorPermissions(false);
    }
  };

  const handleRemoveUserEditorPermissions = async () => {
    try {
      setRemovingUserEditorPermissions(true);
      await firebase.removeUserEditorPermissions({
        uid: uidOfUserToEditPermissionsOf
      });
    } catch (error) {
      console.error(error);
    } finally {
      setRemovingUserEditorPermissions(false);
    }
  };

  const handleGenerateEventCommentsReport = async () => {
    try {
      setGeneratingEventCommentsReport(true);
      const { data } = await firebase.generateEventCommentsReport({
        eid: selectedEvent.eid,
        eventName: selectedEvent.name
      });
      return window.open(data, '_blank') || window.location.replace(data);
    } catch (error) {
      console.error(error);
    } finally {
      setGeneratingEventCommentsReport(false);
    }
  };

  const handleSendEventReminderEmail = async () => {
    try {
      setSendingEventReminderEmail(true);
      await firebase.sendEventReminderEmail({
        event: selectedEvent,
        templateAlias: eventReminderType.value
      });
    } catch (error) {
      console.error(error);
    } finally {
      setSendingEventReminderEmail(false);
    }
  };

  const handleSendEventCancellationEmail = async () => {
    try {
      setSendingEventCancellationEmail(true);
      await firebase.sendEventCancellationEmail({ event: selectedEvent });
    } catch (error) {
      console.error(error);
    } finally {
      setSendingEventCancellationEmail(false);
    }
  };

  const handleGetInvalidUIDs = async () => {
    try {
      setGettingInvalidUIDs(true);
      const { data: allUsers } = await firebase.fetchAllFirebaseAuthUserData();

      const promises = [];
      const results = [];

      allUsers.forEach((_user) => {
        promises.push(
          firebase.getUserFromDatabaseWithUID({ uid: _user.uid }).then((res) => {
            if (res.empty) {
              // eslint-disable-next-line no-console
              results.push(_user.uid);
            } else {
              // eslint-disable-next-line no-console
              results.push('OK');
            }
          })
        );
      });

      await Promise.all(promises);

      // eslint-disable-next-line no-console
      results.forEach((result) => console.log(result));
    } catch (error) {
      console.error(error);
    } finally {
      setGettingInvalidUIDs(false);
    }
  };

  const handleAddDummyUsers = async () => {
    if (amountOfDummyUsers) {
      try {
        setAddingNewDummyUsers(true);
        await firebase.addDummyUsers({
          amountOfDummyUsers,
          eventIds: events.map(({ eid }) => eid)
        });
      } catch (error) {
        console.error(error);
      } finally {
        setAddingNewDummyUsers(false);
      }
    }
  };

  const handleDeleteDummyUsers = async () => {
    try {
      setDeletingAllDummyUsers(true);
      await firebase.deleteAllDummyUsers();
    } catch (error) {
      console.error(error);
    } finally {
      setDeletingAllDummyUsers(false);
    }
  };

  return (
    <ProtectedRoute>
      <SEO pageSpecificTitle="Admin" />
      <Wrapper>
        {!user ? (
          <LoadingSpinnerFullScreenWrapper>
            <LoadingSpinner style={{ width: '7.5rem', color: defaultColors.primary }} />
          </LoadingSpinnerFullScreenWrapper>
        ) : (
          <>
            <UIDInput
              type="text"
              name="uid"
              placeholder="UID"
              value={uidOfUserToEditPermissionsOf}
              colors={defaultColors}
              onChange={(e) => setUidOfUserToEditPermissionsOf(e.target.value)}
            />
            <FormSelect
              defaultValue={selectedEvent.eid ?? ''}
              value={events.filter(({ value }) => value === selectedEvent.eid)}
              onChange={setSelectedEvent}
              placeholder="Event Id"
              styles={customDropdownMenuStyles(defaultColors)}
              options={events}
            />
            <br />
            <br />
            <ButtonExt
              onClick={handleMakeUserAnEventModerator}
              colors={defaultColors}
              disabled={!uidOfUserToEditPermissionsOf}>
              {grantingUserModeratorPermissions ? (
                <LoadingSpinner style={{ width: '2.5rem', color: '#fff' }} />
              ) : (
                <span>
                  Make User An
                  <br />
                  Event Moderator
                </span>
              )}
            </ButtonExt>
            <ButtonExt
              onClick={handleRemoveUserModeratorPermissions}
              colors={defaultColors}
              disabled={!uidOfUserToEditPermissionsOf}>
              {removingUserModeratorPermissions ? (
                <LoadingSpinner style={{ width: '2.5rem', color: '#fff' }} />
              ) : (
                <span>
                  Remove User&apos;s
                  <br />
                  Moderator Permissions
                </span>
              )}
            </ButtonExt>
            <ButtonExt
              onClick={handleMakeUserAnEventEditor}
              colors={defaultColors}
              disabled={!uidOfUserToEditPermissionsOf}>
              {grantingUserEditorPermissions ? (
                <LoadingSpinner style={{ width: '2.5rem', color: '#fff' }} />
              ) : (
                <span>
                  Make User An
                  <br />
                  Event Editor
                </span>
              )}
            </ButtonExt>
            <ButtonExt
              onClick={handleRemoveUserEditorPermissions}
              colors={defaultColors}
              disabled={!uidOfUserToEditPermissionsOf}>
              {removingUserEditorPermissions ? (
                <LoadingSpinner style={{ width: '2.5rem', color: '#fff' }} />
              ) : (
                <span>
                  Remove User&apos;s
                  <br />
                  Editor Permissions
                </span>
              )}
            </ButtonExt>
            <ButtonExt
              onClick={handleMakeUserASiteAdmin}
              colors={defaultColors}
              disabled={!uidOfUserToEditPermissionsOf}>
              {grantingUserAdminPermissions ? (
                <LoadingSpinner style={{ width: '2.5rem', color: '#fff' }} />
              ) : (
                <span>
                  Make User
                  <br />A Site Admin
                </span>
              )}
            </ButtonExt>
            <br />
            <br />
            <ButtonExt
              onClick={handleGenerateLoggedInOnTheDayReport}
              colors={defaultColors}
              disabled={!selectedEvent}>
              {generatingLoggedInOnTheDayReport ? (
                <LoadingSpinner style={{ width: '2.5rem', color: '#fff' }} />
              ) : (
                <span>
                  Generate Logged In
                  <br />
                  On The Day Report
                </span>
              )}
            </ButtonExt>
            <ButtonExt
              onClick={handleGenerateEventCommentsReport}
              colors={defaultColors}
              disabled={!selectedEvent}>
              {generatingEventCommentsReport ? (
                <LoadingSpinner style={{ width: '2.5rem', color: '#fff' }} />
              ) : (
                <span>
                  Generate Event
                  <br />
                  Comments Report
                </span>
              )}
            </ButtonExt>
            <br />
            <br />
            <FormSelect
              id="reminder-types"
              name="reminder-types"
              value={eventReminderType}
              controlShouldRenderValue
              onChange={setEventReminderType}
              placeholder="Reminder Type"
              styles={customDropdownMenuStyles(defaultColors)}
              options={reminderTypes}
            />
            <ButtonExt
              onClick={handleSendEventReminderEmail}
              colors={defaultColors}
              disabled={!selectedEvent}>
              {sendingEventReminderEmail ? (
                <LoadingSpinner style={{ width: '2.5rem', color: '#fff' }} />
              ) : (
                <span>
                  Send Event
                  <br />
                  Reminder Email
                </span>
              )}
            </ButtonExt>
            <br />
            <ButtonExt
              onClick={handleSendEventCancellationEmail}
              colors={defaultColors}
              disabled={!selectedEvent}>
              {sendingEventCancellationEmail ? (
                <LoadingSpinner style={{ width: '2.5rem', color: '#fff' }} />
              ) : (
                <span>
                  Send Event
                  <br />
                  Cancellation Email
                </span>
              )}
            </ButtonExt>
            <br />
            <br />
            <ButtonExt onClick={handleGetInvalidUIDs} colors={defaultColors}>
              {gettingInvalidUIDs ? (
                <LoadingSpinner style={{ width: '2.5rem', color: '#fff' }} />
              ) : (
                'Get Invalid UIDs'
              )}
            </ButtonExt>
            <br />
            <br />
            <AmountOfDummyUsersInput
              type="number"
              name="amountOfDummyUsers"
              placeholder="Amount Of Dummy Users"
              value={amountOfDummyUsers}
              colors={defaultColors}
              onChange={(e) => setAmountOfDummyUsers(e.target.value)}
            />
            <ButtonExt onClick={handleAddDummyUsers} colors={defaultColors}>
              {addingNewDummyUsers ? (
                <LoadingSpinner style={{ width: '2.5rem', color: '#fff' }} />
              ) : (
                'Add Dummy Users'
              )}
            </ButtonExt>
            <br />
            <ButtonExt onClick={handleDeleteDummyUsers} colors={defaultColors}>
              {deletingAllDummyUsers ? (
                <LoadingSpinner style={{ width: '2.5rem', color: '#fff' }} />
              ) : (
                'Delete All Dummy Users'
              )}
            </ButtonExt>
            <br />
            <br />
          </>
        )}
      </Wrapper>
    </ProtectedRoute>
  );
}

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  grid-column: 1 / -1;
  margin: 2.55rem auto 0;
  max-width: 300px;
  padding: 7rem 6px 0;
  width: 100%;

  > * {
    width: 100%;
  }
`;

const UIDInput = styled.input`
  border: ${({ colors }) => `1px solid ${colors.primary}`};
  border-radius: 2px;
  color: ${({ colors }) => colors.primary};
  font-family: 'Noto Sans', sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: bold;
  height: 40px;
  margin-bottom: 12px;
  padding: 0.6em;
  width: 100%;

  &:focus {
    border: ${({ colors }) => `0.143em solid ${colors.primary}`};
    outline: none !important;
  }

  &::placeholder {
    color: ${({ colors }) => colors.primary};
    font-style: italic;
    font-weight: normal;
    opacity: 0.75;
  }
`;

const AmountOfDummyUsersInput = styled(UIDInput)``;

const ButtonExt = styled(Button).attrs(
  ({ disabled, colors }) =>
    !disabled && {
      whileHover: {
        backgroundColor: colors.secondary
      },
      whileTap: {
        scale: 0.95
      }
    }
)`
  background-color: ${({ colors }) => colors.primary};
  border-radius: 2px;
  font-size: 1rem;
  height: 56px;
  line-height: 1.2em;
  margin-bottom: 0.75rem;
  padding: 0.625rem;
  text-transform: capitalize;
  width: 220px;
`;

export default Admin;
